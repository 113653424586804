import React, { useEffect, useState } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';

// Components
import PinboardEditor from '../common/PinboardEditor';
import { Link, useNavigate, useParams } from 'react-router-dom';

// Constants
import { PINBOARD_LIST } from 'constants/routes';

function PinboardEditPage() {
  const { t } = useTranslation(['general']);

  const navigate = useNavigate();
  const params = useParams();

  const { get } = useAPI();

  const [entry, setEntry] = useState();

  useEffect(() => {
    const { id } = params;

    if (!id) {
      navigate(PINBOARD_LIST);
      return;
    }

    const loadPinboardEntry = async () => {
      try {
        const _entry = await get(`/pinboard/created/${id}`);
        setEntry(_entry);
      } catch (error) {
        navigate(PINBOARD_LIST);
        console.error(error);
      }
    };

    loadPinboardEntry();
  }, []);

  return (
    <div className="is-min-fullheight mb-6 mt-5">

      <div className="columns is-centered">
        <div className="column is-11">
          <div>
            <Link
              to={PINBOARD_LIST}
              className="button has-fullwidth has-background-pinboard-red has-text-left has-text-weight-bold"
            >
              <i className="fas fa-square-caret-left has-text-white is-size-4 has-text-white mr-2" />
              {t('pinboard:back_to_pinboard')}
            </Link>
          </div>

          <h1 className="mt-6 is-size-1 has-text-primary has-text-weight-bold">
            {t('general:edit_pinboard_entry')}
          </h1>

          <div className="mt-6">
            {
              entry ? (
                <PinboardEditor
                  entry={entry}
                />
              ) : (
                <div className="has-text-centered">
                  <i className="fas fa-spinner fa-spin fa-2x has-text-primary" />
                </div>
              )
            }
          </div>
        </div>
      </div>

    </div>
  );
}

export default PinboardEditPage;
