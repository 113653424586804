const PINBOARD_ENTRY_TYPES = ['offer', 'search'];

const PINBOARD_PROVIDER_TYPES = ['player', 'team', 'organization', 'company', 'coach', 'manager', 'designer', 'caster', 'producer', 'streamer', 'social_media_manager', 'analyst', 'cutter', 'programmer'];

const PINBOARD_AMIBITION_TYPES = ['competitive', 'just_for_fun', 'paid', 'unpaid'];

const PINBOARD_SPECIAL_ICONS = ['rival_rush', 'fragster_league'];

const PINBOARD_ENTRY_MAX_TITLE_LENGTH = 200;
const PINBOARD_ENTRY_MAX_SUBTITLE_LENGTH = 200;
const PINBOARD_ENTRY_MAX_DESCRIPTION_LENGTH = 1000;

// Contact Info
const PINBOARD_ENTRY_MAX_CONTACT_LENGTH = 100;

export {
  PINBOARD_ENTRY_TYPES,
  PINBOARD_PROVIDER_TYPES,
  PINBOARD_AMIBITION_TYPES,
  PINBOARD_SPECIAL_ICONS,

  PINBOARD_ENTRY_MAX_TITLE_LENGTH,
  PINBOARD_ENTRY_MAX_SUBTITLE_LENGTH,
  PINBOARD_ENTRY_MAX_DESCRIPTION_LENGTH,

  PINBOARD_ENTRY_MAX_CONTACT_LENGTH,
};
