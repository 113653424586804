import React, { useMemo } from 'react';

// Components
import { Link } from 'react-router-dom';
import CardPicDisplay from 'components/utils/CardPicDisplay';
import GameIconDisplay from 'components/utils/games/GameIconDisplay';
import PlatformDisplay from 'components/utils/PlatformDisplay';

// Hooks
import { useTranslation } from 'react-i18next';

// Constants
import { PINBOARD_EDIT, PINBOARD_PAGE } from 'constants/routes';

// Assets
import paidSvg from 'assets/images/icons/paid.svg';
import unpaidSvg from 'assets/images/icons/unpaid.svg';
import competitivePng from 'assets/images/icons/engagement_competitive.png';
import justForFunPng from 'assets/images/icons/engagement_just4fun.png';
import rivalRushPng from 'assets/images/branded/rival_rush.png';

function PinboardEntryDisplay({ entry, linkToEdit = false }) {
  const {
    publicId, entryType, providerType, consumerType, specialIcons, ambitionTypes, qualification, game, platform, updatedAt, title, subTitle, logoPic,
  } = entry;

  const { t } = useTranslation(['general']);

  const wasUpdatedShortly = useMemo(() => {
    const updatedAtDate = new Date(updatedAt);
    const now = new Date();
    const diff = now - updatedAtDate;
    const diffInMinutes = Math.floor(diff / 60000);

    return diffInMinutes < 60;
  }, [updatedAt]);

  const renderedAmbitionTypes = useMemo(() => ambitionTypes.map((ambitionType) => {
    let icon = '';
    switch (ambitionType) {
      case 'just_for_fun':
        icon = justForFunPng;
        break;
      case 'competitive':
        icon = competitivePng;
        break;
      case 'paid':
        icon = paidSvg;
        break;
      case 'unpaid':
        icon = unpaidSvg;
        break;
      default:
        break;
    }

    return (
      <div
        key={ambitionType}
        className="has-background-darkest py-1 px-2 has-text-white has-text-weight-semibold is-flex has-content-centered br-100 is-size-7 has-height-30 ml-1"
      >
        {
            icon && (
            <img
              src={icon}
              alt={ambitionType}
              className="has-width-20 has-height-20 is-block mr-1"
            />
            )
        }
        <p>
          {t(`general:${ambitionType}`)}
        </p>
      </div>
    );
  }), [ambitionTypes, t]);

  const renderedSpecialIcons = useMemo(() => specialIcons.map((specialIcon) => {
    switch (specialIcon) {
      case 'rival_rush':
        return (
          <img
            key={specialIcon}
            src={rivalRushPng}
            alt={specialIcon}
            className=""
          />
        );
      default:
        return null;
    }
  }), [specialIcons]);

  return (
    <Link
      to={linkToEdit ? `${PINBOARD_EDIT}/${publicId}` : `${PINBOARD_PAGE}/${publicId}`}
    >
      <div className="box p-1">
        <div className="columns is-multiline">

          <div className="column is-6">
            <p
              className="has-text-pinboard-green has-text-weight-light"
            >
              {`${t(`general:${providerType}`)} ${entryType === 'offer' ? t('general:offers') : t('general:searches')} ${t(`general:${consumerType}`)}`}
            </p>
            <p
              className="abbreviate-text has-text-white has-text-weight-medium mt-1 is-size-5"
            >
              { title }
            </p>
            {
                subTitle && (
                <p
                  className="has-text-white has-text-weight-light is-size-7 mt-1"
                >
                  {subTitle }
                </p>
                )
            }
          </div>
          <div className="column">
            <div
              className="is-flex flex-wrap has-content-end has-fullheight"
            >
              {renderedAmbitionTypes}
            </div>
          </div>

          <div className="column is-1">
            <div className="is-flex has-content-centered has-fullheight">
              {renderedSpecialIcons}
            </div>
          </div>

          <div className="column is-narrow is-flex flex-direction-column">
            {
                wasUpdatedShortly && (
                <div className="has-text-centered">
                  <p className="has-text-pinboard-green">
                    {t('general:fresh').toUpperCase()}
                  </p>
                </div>
                )
            }

            <div className="is-flex has-items-bottom flex-grow">
              {
                game && game.tag && (
                  <div className="has-width-25 is-flex mr-1">
                    <GameIconDisplay
                      game={game.tag}
                      iconType="white"
                    />
                  </div>
                )
              }
              {
                platform && (
                  <div className="has-width-25 is-flex ml-1">
                    <PlatformDisplay platform={platform} />
                  </div>
                )
              }
            </div>
          </div>

          <div className="column is-1">
            { logoPic && (
            <CardPicDisplay
              img={logoPic}
              url="/images/pinboard/logo_pictures/"
            />
            )}
          </div>

        </div>
      </div>
    </Link>
  );
}

export default PinboardEntryDisplay;
