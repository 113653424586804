import React, {
  useState, useEffect, useMemo, useLayoutEffect,
} from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// Constants
import { PINBOARD_CREATE, RECIPE_PAGE } from 'constants/routes';

// Assets
import header from 'assets/images/backgrounds/pinboard_header.png';
import CreatedPinboardDisplay from './CreatedPinboardDisplay';
import useAPI from 'components/hooks/useAPI';
import PinboardEntryDisplay from '../common/PinboardEntryDisplay';

function PinboardList() {
  const [pinboardEntries, setPinboardEntries] = useState([]);
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [filter, setFilter] = useState({
    entryType: '',
    providerType: '',
    consumerType: '',
    game: { tag: '' },
    platform: '',
    ambitionTypes: [],
  });

  const { language } = useSelector((state) => state.session);
  const { t } = useTranslation(['general', 'errors']);
  const { post } = useAPI();

  const loadEntries = async (updatedAfter = '') => {
    try {
      setDisabled(true);
      const data = { filter };
      const entries = await post('/pinboard/list', data);

      if (updatedAfter) {
        setPinboardEntries([...pinboardEntries, ...entries]);
      } else {
        setPinboardEntries(entries);
      }

      if (entries.length < 20) {
        setShowLoadMoreButton(false);
      } else {
        setShowLoadMoreButton(true);
      }

      setDisabled(false);
    } catch (error) {
      console.error(error);
      setDisabled(false);
    }
  };

  useEffect(() => {
    loadEntries();
  }, []);

  const loadMoreEntries = async () => {
    loadEntries(pinboardEntries[pinboardEntries.length - 1].updatedAt);
  };

  const renderedEntries = pinboardEntries.map((entry, index) => {
    const { publicId } = entry;
    return (
      <div key={publicId} className="my-2">
        <PinboardEntryDisplay entry={entry} />
      </div>
    );
  });

  return (
    <div className="is-min-fullheight is-relative has-text-weight-light">

      <div className="columns">
        <div className="column is-6 px-6 is-flex has-content-centered is-relative">
          <div className="">
            <p className="has-text-primary is-size-4 has-text-weight-medium">
              {t('general:your_transfer_market')}
            </p>
            <h1
              className="is-outlined has-text-weight-bold has-text-white has-text-standard"
              style={{ fontSize: '48px', zIndex: 10 }}
            >
              {t('general:pinboard').toUpperCase()}
            </h1>
            <p
              className="has-text-white mt-3 is-size-5 has-text-weight-light"
            >
              {t('general:pinboard_description')}
            </p>
          </div>
        </div>
        <div className="column p-0">
          <img
            src={header}
            alt={t('general:pinboard')}
            className="has-fullwidth"
          />
        </div>
      </div>

      <div className="columns is-centered">
        <div className="column is-11">
          <CreatedPinboardDisplay />
        </div>
      </div>

      <div className="columns is-centered">
        <div className="column is-11 p-0">

          <div className="columns">
            <div className="column is-narrow">
              <h2 className="is-size-3 has-text-standard has-text-weight-light">
                <i className="fas fa-play mr-4 has-text-primary" />
                {t('general:current_entries')}
              </h2>
            </div>
            <div className="column has-text-right">
              FILTER
            </div>
          </div>

          <div className="my-3">
            {
              !disabled && renderedEntries.length === 0 ? (
                <div className="has-text-centered has-text-weight-semibold">
                  <p>{t('pinboard:no_entries_found')}</p>
                </div>
              ) : renderedEntries
            }
          </div>

          {
            showLoadMoreButton && (
              <div className="has-text-centered">
                <button
                  type="button"
                  onClick={loadMoreEntries}
                  disabled={disabled}
                  className={`button has-text-white has-text-weight-semibold has-background-primary my-4 grow ${disabled ? 'is-loading' : ''}`}
                >
                  {t('general:load_more')}
                </button>
              </div>
            )
          }

        </div>
      </div>

    </div>
  );
}

export default PinboardList;
