import React, { useMemo, useState } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';

// Constants
import {
  PINBOARD_AMIBITION_TYPES, PINBOARD_ENTRY_MAX_DESCRIPTION_LENGTH, PINBOARD_ENTRY_MAX_SUBTITLE_LENGTH, PINBOARD_ENTRY_MAX_TITLE_LENGTH, PINBOARD_ENTRY_TYPES, PINBOARD_PROVIDER_TYPES, PINBOARD_SPECIAL_ICONS,
} from 'constants/pinboard';

// Components
import PinboardDropdown from './PinboardDropdown';
import { useSelector } from 'react-redux';
import GameSelector from 'components/utils/games/GameSelector';
import PlatformSelector from 'components/utils/PlatformSelector';
import {
  DiscordContact, EmailContact, InstagramContact, TagsggContact, TwitterContact, WebsiteContact,
  YoutubeContact,
} from 'components/user/Contacts';
import AsyncImageUploadPopup from 'components/utils/images/AsyncImageUploadPopup';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import useAPI from 'components/hooks/useAPI';
import { useNavigate } from 'react-router-dom';
import { PINBOARD_LIST } from 'constants/routes';

const DEFAULT_ENTRY = {
  publicId: '',
  entryType: PINBOARD_ENTRY_TYPES[0],
  providerType: PINBOARD_PROVIDER_TYPES[0],
  consumerType: PINBOARD_PROVIDER_TYPES[0],
  logoPic: '',
  specialIcons: [],
  ambitionTypes: [],
  qualification: '',
  title: '',
  subTitle: '',
  description: '',
  game: { tag: 'RL' },
  platform: 'pc',
  contact: {
    email: '',
    website: '',
    xcom: '',
    instagram: '',
    youtube: '',
    twitch: '',
    discord: '',
  },
  isLive: false,
  hasAcceptedTos: false,
};

function PinboardEditor({ entry = DEFAULT_ENTRY }) {
  const { t } = useTranslation(['general', 'pinboard']);

  const { player } = useSelector((state) => state.player);
  const { specialQualifications } = player;
  const allQualifications = useMemo(() => specialQualifications.map((qualification) => ({
    value: qualification,
    label: t(`general:${qualification}`),
  })), [specialQualifications, t]);

  const [publicId, setPublicId] = useState(entry.publicId);

  const [entryType, setEntryType] = useState(entry.entryType);
  const [providerType, setProviderType] = useState(entry.providerType);
  const [consumerType, setConsumerType] = useState(entry.consumerType);

  const [logoPic, setLogoPic] = useState(entry.logoPic);
  const [logoBlob, setLogoBlob] = useState(null);

  const [specialIcons, setSpecialIcons] = useState(entry.specialIcons);
  const [ambitionTypes, setAmbitionTypes] = useState(entry.ambitionTypes);
  const [qualification, setQualification] = useState(entry.qualification);

  const [title, setTitle] = useState(entry.title);
  const [subTitle, setSubTitle] = useState(entry.subTitle);
  const [description, setDescription] = useState(entry.description);

  const [game, setGame] = useState(entry.game);
  const [platform, setPlatform] = useState(entry.platform);

  const [contact, setContact] = useState(entry.contact);

  const [isLive, setIsLive] = useState(entry.isLive);
  const [hasAcceptedTos, setHasAcceptedTos] = useState(entry.isLive);

  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState('');

  const navigate = useNavigate();

  const { post, patch, del } = useAPI();

  const prepareLogo = async (blob) => {
    URL.revokeObjectURL(logoPic);
    setLogoPic(URL.createObjectURL(blob));
    setLogoBlob(blob);
  };

  const deleteEntry = async () => {
    try {
      setStatus('');
      setDisabled(true);

      const data = { publicId };
      await post('/pinboard/delete', data);

      navigate(PINBOARD_LIST);
    } catch (e) {
      setDisabled(false);
      setStatus(`${t('pinboard:delete_failed_error')}`);
      console.log(e);
    }
  };

  const saveEntry = async (isPublishCall = false) => {
    try {
      setStatus('');
      setDisabled(true);

      if (!hasAcceptedTos) {
        setStatus(`${t('pinboard:tos_not_accepted_error')}`);
        setDisabled(false);
        return;
      }

      if (!title) {
        setStatus(`${t('pinboard:title_required_error')}`);
        setDisabled(false);
        return;
      }

      const data = new FormData();
      data.append('entryType', entryType);
      data.append('providerType', providerType);
      data.append('consumerType', consumerType);
      data.append('specialIcons', JSON.stringify(specialIcons));
      data.append('ambitionTypes', JSON.stringify(ambitionTypes));
      data.append('qualification', qualification);
      data.append('title', title);
      data.append('subTitle', subTitle);
      data.append('description', description);
      data.append('game', JSON.stringify(game));
      data.append('platform', platform);
      data.append('contact', JSON.stringify(contact));
      data.append('isLive', isPublishCall || isLive);

      if (logoBlob) {
        data.append('logoPic', logoBlob);
      }

      if (!publicId) {
        data.append('publicId', '');
        const _publicId = await post('/pinboard/', data);
        setPublicId(_publicId);
      } else {
        data.append('publicId', publicId);
        await patch('/pinboard/', data);
      }

      setIsLive(isPublishCall || isLive);
      setStatus(`${t('pinboard:saved_successfully')}`);
      setDisabled(false);
    } catch (e) {
      console.log(e);

      setStatus(`${t('pinboard:save_failed_error')}`);
      setDisabled(false);
    }
  };

  const _setTitle = (_title) => {
    if (_title.length > PINBOARD_ENTRY_MAX_TITLE_LENGTH) {
      return;
    }

    setTitle(_title);
  };

  const _setSubTitle = (_subTitle) => {
    if (_subTitle.length > PINBOARD_ENTRY_MAX_SUBTITLE_LENGTH) {
      return;
    }

    setSubTitle(_subTitle);
  };

  const _setDescription = (_description) => {
    if (_description.length > PINBOARD_ENTRY_MAX_DESCRIPTION_LENGTH) {
      return;
    }

    setDescription(_description);
  };

  return (
    <div>
      <div className="field has-border-bottom-grey pb-5">
        <div className="control">
          <PinboardDropdown
            label="entry_type_label"
            description="entry_type_description"
            value={entryType}
            setValue={setEntryType}
            availableValues={PINBOARD_ENTRY_TYPES}
          />
        </div>
      </div>

      <div className="field has-border-bottom-grey pb-5 mt-5">
        <div className="control">
          <PinboardDropdown
            label="provider_type_label"
            description="provider_type_description"
            value={providerType}
            setValue={setProviderType}
            availableValues={PINBOARD_PROVIDER_TYPES}
          />
        </div>
      </div>

      <div className="field has-border-bottom-grey pb-5 mt-5">
        <div className="control">
          <PinboardDropdown
            label="consumer_type_label"
            description="consumer_type_description"
            value={consumerType}
            setValue={setConsumerType}
            availableValues={PINBOARD_PROVIDER_TYPES}
          />
        </div>
      </div>

      <div className="field has-border-bottom-grey pb-5 mt-5">
        <div className="control">
          <div className="columns is-multiline">
            <div className="column is-6">
              <p className="has-text-weight-bold is-size-5">
                {t('pinboard:logo_label')}
              </p>
              <p className="mt-2">
                {t('pinboard:logo_description')}
              </p>
            </div>
            <div className="column">
              <AsyncImageUploadPopup
                label="logo"
                bestSize="200x200"
                width={200}
                height={200}
                onSave={prepareLogo}
                imgPath={logoPic && !logoPic.startsWith('blob') ? `/pinboard/logo_pictures/${logoPic}` : logoPic}
                closePopupAfterSave
              />
            </div>
          </div>
        </div>
      </div>

      <div className="field has-border-bottom-grey pb-5 mt-5">
        <div className="control">
          <PinboardDropdown
            label="ambition_types_label"
            description="ambition_types_description"
            value={ambitionTypes}
            isMulti
            setValue={setAmbitionTypes}
            availableValues={PINBOARD_AMIBITION_TYPES}
          />
        </div>
      </div>

      <div className="field has-border-bottom-grey pb-5 mt-5">
        <div className="control">
          <PinboardDropdown
            label="special_icons_label"
            description="special_icons_description"
            value={specialIcons}
            isMulti
            setValue={setSpecialIcons}
            availableValues={PINBOARD_SPECIAL_ICONS}
          />
        </div>
      </div>

      {
        allQualifications.length > 0 && (
          <div className="field has-border-bottom-grey pb-5 mt-5">
            <div className="control">
              <PinboardDropdown
                label="qualification_label"
                description="qualification_description"
                value={qualification}
                setValue={setQualification}
                availableValues={allQualifications}
              />
            </div>
          </div>
        )
      }

      <div className="field has-border-bottom-grey pb-5 mt-5">
        <div className="control">
          <div className="columns is-multiline">
            <div className="column is-3">
              <p className="has-text-weight-bold is-size-5">
                {t('pinboard:content_label')}
              </p>
              <p className="mt-2">
                {t('pinboard:content_description')}
              </p>
            </div>
            <div className="column">
              <input
                className="input"
                type="text"
                placeholder={t('pinboard:title_placeholder')}
                value={title}
                onChange={(e) => _setTitle(e.target.value)}
              />
              <input
                className="input my-4"
                type="text"
                placeholder={t('pinboard:subtitle_placeholder')}
                value={subTitle}
                onChange={(e) => _setSubTitle(e.target.value)}
              />
              <textarea
                className="textarea"
                placeholder={t('pinboard:description_placeholder')}
                value={description}
                onChange={(e) => _setDescription(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="field has-border-bottom-grey pb-5 mt-5">
        <div className="control">
          <div className="columns is-multiline">
            <div className="column">
              <p className="has-text-weight-bold is-size-5">
                {t('pinboard:game_label')}
              </p>
              <p className="mt-2">
                {t('pinboard:game_description')}
              </p>
            </div>
            <div className="column">
              <GameSelector
                game={game}
                setGame={(tag) => setGame({ tag })}
                filter="NL"
              />
              <div className="mt-4">
                <PlatformSelector
                  game={game.tag}
                  platform={platform}
                  setPlatform={setPlatform}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="field has-border-bottom-grey pb-5 mt-5">
        <div className="control">
          <div className="columns is-multiline">
            <div className="column">
              <p className="has-text-weight-bold is-size-5">
                {t('pinboard:contact_label')}
              </p>
              <p className="mt-2">
                {t('pinboard:contact_description')}
              </p>
            </div>
            <div className="column">
              <EmailContact
                value={contact.email}
                setValue={(_, name) => setContact({ ...contact, email: name })}
              />
              <WebsiteContact
                value={contact.website}
                setValue={(_, name) => setContact({ ...contact, website: name })}
              />
              <DiscordContact
                value={contact.discord}
                setValue={(_, name) => {
                  setContact({ ...contact, discord: name });
                }}
              />
              <InstagramContact
                value={contact.instagram}
                setValue={(_, name) => setContact({ ...contact, instagram: name })}
              />
              <TwitterContact
                value={contact.xcom}
                setValue={(_, name) => setContact({ ...contact, xcom: name })}
              />
              <YoutubeContact
                value={contact.youtube}
                setValue={(_, name) => setContact({ ...contact, youtube: name })}
              />
              <TagsggContact
                value={contact.twitch}
                setValue={(_, name) => setContact({ ...contact, twitch: name })}
              />
            </div>
          </div>
        </div>
      </div>

      {
        !isLive && (
          <div className="field pb-5 mt-5">
            <div className="control">
              <div className="">
                <p className="has-text-weight-bold is-size-5">
                  {t('pinboard:tos_label')}
                </p>
                <p className="mt-2">
                  {t('pinboard:tos_description')}
                </p>

                <div className="box is-flex has-content-centered-vertically mt-4">
                  <input
                    type="checkbox"
                    className="checkbox"
                    checked={hasAcceptedTos}
                    onChange={() => setHasAcceptedTos(!hasAcceptedTos)}
                  />
                  <p className="ml-3">
                    {t('pinboard:tos_full_text')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )
      }

      <div className="field pb-5 mt-5">
        <div className="columns">
          <div className="column">
            {
              isLive ? (
                <button
                  key="publish"
                  type="button"
                  className={`button is-success is-size-3 ${disabled ? 'is-loading' : ''}`}
                  onClick={() => saveEntry(false)}
                  disabled={disabled}
                >
                  {t('general:save')}
                </button>
              ) : (
                <button
                  key="publish"
                  type="button"
                  className={`button is-success is-size-3 ${disabled ? 'is-loading' : ''}`}
                  onClick={() => saveEntry(false)}
                  disabled={disabled}
                >
                  {t('pinboard:just_save')}
                </button>
              )
            }
          </div>
          <div className="column has-text-right">
            {
              !isLive && (
                <button
                  key="publish"
                  type="button"
                  className={`button mb-5 is-block has-margin-left-auto is-success is-size-3 ${disabled ? 'is-loading' : ''}`}
                  onClick={() => saveEntry(true)}
                  disabled={disabled}
                >
                  {t('pinboard:publish_now')}
                </button>
              )
            }
            {
              publicId && (
                <button
                  key="publish"
                  type="button"
                  className={`button is-block has-margin-left-auto is-danger is-size-3 ${disabled ? 'is-loading' : ''}`}
                  onClick={deleteEntry}
                  disabled={disabled}
                >
                  {t('pinboard:delete_entry')}
                </button>
              )
            }
          </div>
        </div>

        <div className="has-text-centered mt-5 is-size-5 has-min-height-30">
          {status}
        </div>
      </div>

    </div>
  );
}

export default withAuthenticationRequired(PinboardEditor);
