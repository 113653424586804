import React, { useState, useEffect, useMemo } from 'react';

// Hooks
import useAPI from 'components/hooks/useAPI';
import { useTranslation } from 'react-i18next';

import AnimateHeight from 'react-animate-height';
import PinboardEntryDisplay from 'components/pinboard/common/PinboardEntryDisplay';
import { PINBOARD_CREATE } from 'constants/routes';
import { Link } from 'react-router-dom';

function CreatedPinboardDisplay() {
  const [pinboardEntries, setPinboardEntries] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(false);

  const [height, setHeight] = useState(0);
  const [hasLoaded, setHasLoaded] = useState(false);

  const { post } = useAPI();
  const { t } = useTranslation(['general']);

  const loadEntries = async (updatedAfter = '') => {
    try {
      setDisabled(true);
      const data = { updatedAfter };
      const entries = await post('/pinboard/created', data);

      if (updatedAfter) {
        setPinboardEntries([...pinboardEntries, ...entries]);
      } else {
        setPinboardEntries(entries);
      }

      if (entries.length < 20) {
        setShowLoadMoreButton(false);
      } else {
        setShowLoadMoreButton(true);
      }

      setHeight('auto');
      setHasLoaded(true);
      setDisabled(false);
    } catch (error) {
      console.error(error);
      setDisabled(false);
    }
  };

  useEffect(() => {
    if (!hasLoaded && height !== 0) {
      loadEntries();
    }
  }, [hasLoaded, height]);

  const loadMoreEntries = async () => {
    loadEntries(pinboardEntries[pinboardEntries.length - 1].updatedAt);
  };

  const handleButtonClick = () => {
    if (!hasLoaded) {
      setHeight(height === 0 ? 100 : 0);
      return;
    }

    setHeight(height === 0 ? 'auto' : 0);
  };

  const renderedContent = useMemo(() => {
    if (!hasLoaded) {
      return (
        <div className="has-text-centered mt-5">
          <i className="fas fa-spinner fa-spin fa-2x has-text-primary" />
        </div>
      );
    }

    if (pinboardEntries.length === 0) {
      return (
        <div className="has-text-centered mt-6 has-text-weight-semibold">
          <p>{t('pinboard:you_have_not_created_any_entries')}</p>
        </div>
      );
    }

    return pinboardEntries.map((entry, index) => {
      const { entryType, publicId } = entry;
      return (
        <div key={publicId} className="my-2">
          <PinboardEntryDisplay entry={entry} linkToEdit />
        </div>
      );
    });
  }, [hasLoaded, pinboardEntries]);

  return (
    <div>
      <div className="columns">
        <div className="column is-narrow p-0">
          <button
            type="button"
            onClick={handleButtonClick}
            className="cleanButton"
          >
            <h2 className="is-size-3 has-text-standard has-text-weight-light">
              {
                height !== 0 ? (
                  <i className="fas fa-play mr-4 has-text-primary" />
                ) : (
                  <i className="fas fa-play mr-4 has-text-grey" />
                )
              }
              {t('general:my_entries')}
            </h2>
          </button>
        </div>
        <div className="column p-0">
          <Link
            to={PINBOARD_CREATE}
            className="button has-no-background grow_small has-text-grey has-border-dashed-grey has-fullheight mx-4"
          >
            <i className="fas fa-plus mr-2" />
            {t('general:create_entry')}
          </Link>
        </div>
      </div>

      <AnimateHeight
        height={height}
      >
        <div>
          <div className="my-3">
            { renderedContent }
          </div>

          {
            showLoadMoreButton && (
              <div className="has-text-centered">
                <button
                  type="button"
                  onClick={loadMoreEntries}
                  disabled={disabled}
                  className={`button has-text-white has-text-weight-semibold has-background-primary my-4 grow ${disabled ? 'is-loading' : ''}`}
                >
                  {t('general:load_more')}
                </button>
              </div>
            )
          }
        </div>
      </AnimateHeight>

    </div>
  );
}

export default CreatedPinboardDisplay;
